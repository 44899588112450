
<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <v-card>
        <v-toolbar flat color="primary" dark>
            <v-toolbar-title>Pruebas</v-toolbar-title>
             <v-spacer></v-spacer>
        </v-toolbar>
    </v-card>
    <v-btn depressed @click = "CopiarDatos">
      Copiar datos
    </v-btn>
  </v-container>
</template>
<script>
import { db, functions } from '../../../plugins/firebase'
import firebase from 'firebase'
export default {
  data () {
    return {
      alertError: '',
      alertSuccess: '',
      
    }
  },
  created () {

  },
  methods: {
    async ObtenerReportesValeria(){
      let demo =[]
      let dataPrueba = await db.collection("SchoolsData").doc("RegionSchoolD").collection("Countries").doc("2r2JWPAq8NRItilttjH6").collection("States").doc("TpFpRZodvG40D5kmvE5z").collection("SchoolDistricts").doc("4dhXa83l2rr4k5rimGRO").collection("Schools").where("SchoolTeachers", "!=", {}).get()
      dataPrueba.forEach(async (doc) => {
        
        // console.log(doc.id);
        let students = await db.collection("SchoolsData").doc("RegionSchoolD").collection("Countries").doc("2r2JWPAq8NRItilttjH6").collection("States").doc("TpFpRZodvG40D5kmvE5z").collection("SchoolDistricts").doc("4dhXa83l2rr4k5rimGRO").collection("Schools").doc(doc.id).collection("StudentsData").doc("P1").get()
        if ((doc.data().TeacherLicencesAssigned > 0  || doc.data().StudentLicencesAssigned > 0 ) && Object.keys(doc.data().SchoolTeachers).length == doc.data().LicencesRegistration && Object.keys(students.data().Students).length > 0){
          demo.push(doc.data().SchoolName +" , "+ Object.keys(doc.data().SchoolTeachers).length +" , "+Object.keys(students.data().Students).length +" , "+ doc.data().TeacherLicencesAssigned+" , "+ doc.data().StudentLicencesAssigned)
        }

        // console.log(Object.keys(students.data().Students).length);

      })
      console.log(demo);
      // .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //         // doc.data() is never undefined for query doc snapshots
      //         // console.log(doc.data().SchoolName +" ; "+ Object.keys(doc.data().SchoolTeachers).length);
      //     });
      //     console.log(demo);    
      // })
      // .catch((error) => {
      //     console.log("Error getting documents: ", error);
      // });
    },
    async CopiarDatos(){
      // let correoLlegada = "padre35@test.com";
      // let uidLlegada= "Oz9lDl1LDuYl4Zwe5wVDQrJXltt1";

      // let correoPartida = "mrg.educating@gmail.com";
      // let uidPartida = "Uz4y6zgXbIXn1t9TNnnQyuhpxFA2";

      let Kid0 = await  db.collection('Parents').doc(uidPartida).collection("Kids").doc("0").get();
      console.log(Kid0.data());
      let Kid1 = await  db.collection('Parents').doc(uidPartida).collection("Kids").doc("1").get();
      console.log(Kid1.data());
      let Kid2 = await  db.collection('Parents').doc(uidPartida).collection("Kids").doc("2").get();
      console.log(Kid2.data());
      
      

      await db.collection('Parents').doc(uidLlegada).collection("Kids").doc("0").update(Kid0.data());
      await db.collection('Parents').doc(uidLlegada).collection("Kids").doc("1").update(Kid1.data());
      await db.collection('Parents').doc(uidLlegada).collection("Kids").doc("2").update(Kid2.data());
    },
    async CambiarMaestroColegio(){
      // let idTeacher = "5fa8de54935b9b0344c851c5";
      // let newSchoolReference= "/SchoolsData/RegionSchoolD/Countries/2r2JWPAq8NRItilttjH6/States/TpFpRZodvG40D5kmvE5z/SchoolDistricts/4dhXa83l2rr4k5rimGRO/Schools/inhHhUtEeBlXNHsbKTxn";
      let dataTeacher= await db.collection("SchoolTeacher").doc(idTeacher).get();
      let teacher = dataTeacher.data();
      let oldSchoolReference = teacher.Reference
      let idsClassroms = Object.keys(teacher.Classrooms);


      let newSchoolData = await db.doc(newSchoolReference).get();
      // cambiar maestros
      var batch = db.batch();
      // var step1 = db.collection("PreUsersClever").doc(data.email);
      // batch.delete(step1);
      let step2 = db.collection('SchoolTeacher').doc(idTeacher);
      batch.update(step2, {
        School: newSchoolData.data().SchoolName,
        Reference: db.doc(newSchoolReference),
        Multiplayer: true,
      });
      batch.update(oldSchoolReference, { [`SchoolTeachers.${[[idTeacher]]}`]: firebase.firestore.FieldValue.delete() });
      batch.update(db.doc(newSchoolReference), { [`SchoolTeachers.${[[idTeacher]]}`]: { Name: teacher.Name, LastName: teacher.LastName, Email: teacher.Email, Enabled: true } });
      // cambiar estudiantes
      for (const idClass of idsClassroms) {
        let classInfo = await db.collection("SchoolTeacher").doc(idTeacher).collection("Classrooms").doc(idClass).get();
        let ListaEstudiantes = Object.keys(classInfo.data().MainGroup);
        for (const idStudent of ListaEstudiantes) {
          let infoStudent = classInfo.data().MainGroup[idStudent]
          console.log(infoStudent);
          batch.update(db.doc(oldSchoolReference.path+'/StudentsData/P1'), { [`Students.${[[idStudent]]}`]: firebase.firestore.FieldValue.delete() });
          batch.update(db.doc(newSchoolReference+'/StudentsData/P1'), { [`Students.${[[idStudent]]}`]: {Email:'', Enabled:true, LastName: infoStudent.LastName, Name:infoStudent.Name, StudentId:'', UserUIDSTeacher:idTeacher } });
          batch.update(db.collection("Students").doc(idStudent), { School: newSchoolData.data().SchoolName })
        }
        console.log(classInfo.data().CodeAccess);
        batch.update(db.collection("CodeAccessStudents").doc(classInfo.data().CodeAccess), { Reference: db.doc(newSchoolReference), School:newSchoolData.data().SchoolName })
        batch.update(oldSchoolReference, { AvailableStudentLicences: firebase.firestore.FieldValue.increment(ListaEstudiantes.length) });
        batch.update(db.doc(newSchoolReference), { AvailableStudentLicences: firebase.firestore.FieldValue.increment(-(ListaEstudiantes.length)) });
        console.log(classInfo.data());
      }

      await batch.commit();
    },
    async MoverEstudiantesMaestrosMismaEscuela(){
      // let idTeacherNuevo = "5fa8de54935b9b0344c8521a";
      // let idTeacherAntiguo = "6539f6326da60a00c94ba869";
      let dataTeacherNuevo= await db.collection("SchoolTeacher").doc(idTeacherNuevo).get();
      let teacherNuevo = dataTeacherNuevo.data();
      let dataTeacher= await db.collection("SchoolTeacher").doc(idTeacherAntiguo).get();
      let teacherAntiguo = dataTeacher.data();
      let idsClassroms = Object.keys(teacherAntiguo.Classrooms);
      let batch = db.batch();

      for (const idClass of idsClassroms) {
        let classInfo = await db.collection("SchoolTeacher").doc(idTeacherAntiguo).collection("Classrooms").doc(idClass).get();
        let ListaEstudiantes = Object.keys(classInfo.data().MainGroup);
        batch.update(db.collection("SchoolTeacher").doc(idTeacherAntiguo), { [`Classrooms.${idClass}.NumberStudents`]: 0 });
        for (const idStudent of ListaEstudiantes) {
          batch.update(db.doc(teacherAntiguo.Reference.path + '/StudentsData/P1'), { [`Students.${idStudent}.UserUIDSTeacher`]: idTeacherNuevo });
          let step1 = db.collection("SchoolTeacher").doc(idTeacherAntiguo).collection('Classrooms').doc(idClass);
          batch.update(step1, { [`MainGroup.${[idStudent]}`]: firebase.firestore.FieldValue.delete() });
          batch.update(db.collection("StudentsUserClever").doc(idStudent), { Create: false, Reference: teacherNuevo.Reference });
          let step2 = db.collection('Students').doc(idStudent);
          batch.update(step2, {
            School: teacherNuevo.School,
            ClassroomPath: db.collection('SchoolTeacher').doc(idTeacherNuevo).collection('Classrooms').doc(idClass).path
          });
          batch.update(db.collection('SchoolTeacher').doc(idTeacherNuevo), { [`Classrooms.${idClass}.NumberStudents`]: firebase.firestore.FieldValue.increment(1) });
          batch.update(db.collection('SchoolTeacher').doc(idTeacherNuevo).collection('Classrooms').doc(idClass), { [`MainGroup.${idStudent}`]: { Email: '', Group: '', Name: classInfo.data().MainGroup[idStudent].Name, LastName: classInfo.data().MainGroup[idStudent].LastName } });
        }
      }
      await batch.commit();
    },
  },
  mounted() {
    // this.ObtenerReportesValeria();
      // this.ManualTeacher();
      // this.CambiarMaestroColegio();
      // this.MoverEstudiantesMaestrosMismaEscuela();
  },
  computed: {

  },
  watch: {

  }
}
</script>
